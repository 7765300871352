body {
  direction: rtl;
  unicode-bidi: embed;
  text-align: right;
}

// Homepage
#hero {
  // Swiper
  .swiper-container {
    direction: rtl;
  }
}

#tech-services,
#latest-articles,
.the-team {
  .tech-services-carousel-container,
  .latest-articles-carousel-container {
    text-align: right;
    .card {
      align-items: flex-start;
    }
  }
  .swiper-button-next {
    left: -15px;
    right: auto;
  }
  .swiper-button-prev {
    left: auto;
    right: -15px;
  }
}

.site-main {
  ul {
    padding: 0 15px 0 0;
  }
}

// Contact
#contact {
  form {
    text-align: right;
    input[type="text"],
    input[type="email"],
	input[type="tel"],
    input[type="number"] {
      direction: rtl;
      text-align: right;
    }
  }
}

.site-footer {
  .social {
    li {
      margin: 0 0 0 15px;
    }
  }
}

@media screen and (min-width: 992px) {
  #portfolio {
    .portfolio {
      .item {
        text-align: right;
      }
    }
  }
}
